<template>
  <!-- 入力/確認フォームの親（データ保持用） -->
  <div>
    <p v-if="receptionErrorMsg" class="text-caccent">{{ receptionErrorMsg }}</p>
    <router-view
      v-bind:stored-data="storedData"
      v-bind:reception-type-list="receptionTypeList"
      v-on:to-confirm="dataSave"
    ></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {
      storedData: {
        // 入力データ保持用 入力確認クリック時に受け取る
        personalInfo: {
          surname: null,
          firstname: null,
          surnameKana: null,
          firstnameKana: null,
          dateOfBirth: null,
          gender: null,
          genderText: null,
          address1: null,
          address2: null,
          address3: null,
          extraAddress1: null,
          extraAddress2: null,
          extraAddress3: null,
          phoneNumber: null,
          email: null,
          receptionType: null,
          receptionTypeName: null,
        },
        ekycPlan: null,
        ekycPlanComfirmed: false,
        fieldRequired: true,
        extraAddressRequired: false,
      },
      receptionTypeList: [], //コイン受け取り方法リスト
      receptionErrorMsg: null,
    }
  },
  created() {
    this.getListItems()
  },
  methods: {
    getListItems() {
      // リスト選択項目取得 ＊コイン受取方法
      console.log('* get document_type *')
      this.receptionErrorMsg = null
      console.log('* get reception_type *')
      this.$axios
        .get('/account_opening/reception_type')
        .then((response) => {
          console.log(response)
          this.receptionTypeList = response.data.receptionTypeList
        })
        .catch((error) => {
          console.log(error)
          this.receptionErrorMsg =
            'エラー : コイン受け取り方法リストの取得に失敗しました。管理者にご連絡ください。'
        })
    },
    dataSave(
      personalInfo,
      ekycPlan,
      ekycPlanComfirmed,
      fieldRequired,
      extraAddressRequired
    ) {
      console.log(
        '**** return',
        personalInfo,
        ekycPlan,
        ekycPlanComfirmed,
        fieldRequired,
        extraAddressRequired
      )
      Object.assign(this.storedData.personalInfo, personalInfo)
      this.storedData.ekycPlan = ekycPlan
      this.storedData.ekycPlanComfirmed = ekycPlanComfirmed
      this.storedData.fieldRequired = fieldRequired
      this.storedData.extraAddressRequired = extraAddressRequired
    },
  },
}
</script>
